import styles from "./Settings.module.scss";
import { Sidebar, DashboardHeader, Aside, ApplicationMenu } from "common";
import React, { useEffect, useState } from "react";
import { LandingPagePortal, Locations, MerchantAccountInformation, Notifications, Users } from ".";
import { merchantAdminSettingsMenu } from "utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { handleInnerMenusBackButton } from "utils/helpers";
import { useFlag } from "hooks/useFlag";
import MerchantSettingsView from "./settings/merchant/MerchantSettingsView";
import InnerPopups from "content/popups/InnerPopups";

const MerchantSettings: React.FC = () => {
  const navigate = useNavigate();
  const { view } = useParams();
  const [viewSettings, setViewSettings] = useState(view || "accountInformation");
  const newSettingsView = useFlag("new-merchant-settings-view");

  useEffect(() => {
    return handleInnerMenusBackButton(merchantAdminSettingsMenu.map(item => { return item.href }), setViewSettings);
  }, []);

  return <main className={styles.settingsPageContainer}>
    <Sidebar />
    <Aside>
      <DashboardHeader />
      <InnerPopups />
      {newSettingsView && <MerchantSettingsView />}
      {!newSettingsView && <>
        {viewSettings === "accountInformation" && <MerchantAccountInformation />}
        {viewSettings === "landingPageSettings" && <LandingPagePortal user_type="MERCHANT" />}
        {viewSettings === "experienceSettings" && <MerchantAccountInformation />}
        {viewSettings === "notifications" && <Notifications />}
        {viewSettings === "users" && <Users />}
        {viewSettings === "locations" && <Locations />}
        <ApplicationMenu
          onClick={(val: string) => {
            setViewSettings(val);
            navigate(`/settings/${val}`);
          }}
          activeList={viewSettings}
          items={merchantAdminSettingsMenu}
        />
      </>}
    </Aside>
  </main>;
};

export default MerchantSettings;
