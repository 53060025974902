import React, { useEffect, useState } from "react";
import styles from "./InformationCard.module.scss";
import { CardTick, SmsEdit, Call, Location, Shop, UserEdit, Global, ProfileCircle, Home, Calendar, Bucket, Edit, MoneyTick, UserCirlceAdd, Tag } from "iconsax-react";
import { Button, CloseIcon, LineWithIcon } from "common";
import { useNavigate, useParams } from "react-router-dom";
import SystemHandler from "actions/SystemHandler";
import Analytics, { ITracking } from "classes/Analytics";
import {
  askForConfirmation, concatenateAddress, displayFeedback, displayMiniFeedback,
  getAllStatuses, getStatus, isForPartnerGlobal
} from "utils/helpers";
import { IAddress, IFeedback } from "common/interfaces";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import { DOMAIN_PREFIX, POPUPS } from "utils/constants";
import { formatAmount, formatAPIDate, formatFullname, formatPhone } from "utils/formatters";
import Observer, { EVENTS } from "classes/Observer";
import { useAuth } from "auth/useAuth";
import { PartnerTabType } from "common/types";
import { PartnerHandler } from "actions/PartnerHandler";
import usePartner from "hooks/usePartner";
import { useFlag } from "hooks/useFlag";

interface IPartnerInformationCard {
  variant: PartnerTabType;
  partnerSlugProp?: string;
  closePartnerSummary?: () => void;
  active?: boolean;
}

const PartnerInformationCard: React.ForwardRefRenderFunction<HTMLDivElement, IPartnerInformationCard> = ({ variant, partnerSlugProp, closePartnerSummary, active }, ref) => {
  const user = useAuth()?.user;
  const whitelabel = useAppSelector(state => state.whitelabel);

  const [annualSalesOptions, setAnnualSalesOptions] = useState([]);
  const [estMonthlyFinanceVolumeOptions, setEstMonthlyFinanceVolumeOptions] = useState([]);
  const [averageTicketOptions, setAverageTicketOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [specialtyOptions, setSpecialtyOptions] = useState([]);
  const [partnerDetailsLabels, setPartnerDetailsLabels] = useState<any>(null);
  const [numberMerchantsOptions, setNumberMerchantsOptions] = useState([]);
  const [partnerTypeOptions, setPartnerTypeOptions] = useState([]);

  const { partnerSlug } = useParams();
  const partnerUpdated = Observer.useObserver(EVENTS.PARTNER_UPDATED);
  const [partnerStatuses, setPartnerStatuses] = useState([]);
  const [isPartnerDeactivated, setIsPartnerDeactivated] = useState(false);
  const businessModelUpdated = Observer.useObserver(EVENTS.BUSINESS_MODEL_UPDATED);

  const getSlug = () => {
    return partnerSlug || partnerSlugProp;
  }

  const partner = usePartner(getSlug());

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    PartnerHandler().getStats(getSlug())
  }, [partnerSlug, partnerSlugProp, businessModelUpdated]);

  useEffect(() => {
    if (partner) {
      getStatus("Deactivated")
        .then(response => {
          setIsPartnerDeactivated(partner?.status === response);
        });
    }
  }, [partner]);

  useEffect(() => {
    Promise.all([
      SystemHandler.getAnnualSales(),
      SystemHandler.getMonthlyFinanceVolume(),
      SystemHandler.getAverageTicket(),
      SystemHandler.getIndustry(),
      SystemHandler.getIndustrySpecialty(),
      SystemHandler.getNumberOfMerchants(),
      SystemHandler.getPartnerTypes()
    ]).then(responses => {
      setAnnualSalesOptions(responses[0]);
      setEstMonthlyFinanceVolumeOptions(responses[1]);
      setAverageTicketOptions(responses[2]);
      setIndustryOptions(responses[3]);
      setSpecialtyOptions(responses[4]);
      setNumberMerchantsOptions(responses[5]);
      setPartnerTypeOptions(responses[6]);
    });
  }, []);

  useEffect(() => {
    if (numberMerchantsOptions?.length > 0 && partnerTypeOptions?.length > 0) {
      setPartnerDetailsLabels({
        merchant_number: _.find(numberMerchantsOptions, option => { return option.id === partner?.merchant_number })?.label || partner?.merchant_number,
        type: _.find(partnerTypeOptions, option => { return option.id === partner?.type })?.label || partner?.type
      })
    }
  }, [annualSalesOptions, estMonthlyFinanceVolumeOptions, averageTicketOptions,
    industryOptions, specialtyOptions, partner, numberMerchantsOptions,
    partnerTypeOptions]);

  useEffect(() => {
    if (window.location.pathname.toLowerCase() === "/settings/experiencesettings") {
      // TODO: check if this is used at all, since this is viewMerchant screen, not settings
      navigate('/settings/accountInformation');
    }
    getAllStatuses()
      .then(response => {
        setPartnerStatuses(response);
      });
  }, []);

  useEffect(() => {
    PartnerHandler().get(getSlug());
  }, [partnerUpdated]);

  useEffect(() => {
    if (getSlug() || !partner?.id) {
      PartnerHandler().get(getSlug());
    }
  }, [partnerSlug, partnerSlugProp]);

  const address: IAddress = {
    address1: partner?.address1,
    address2: partner?.address2 || "",
    city: partner?.city,
    state: partner?.state,
    postal_code: partner?.postal_code
  };

  const handleHistoryClick = () => {
    dispatch(popUpActions.openPopup(POPUPS.PARTNER_HISTORY));
  }

  const getStatusId = (status: "Active" | "Pending" | "Deactivated" | "Declined"): number => {
    return _.find(partnerStatuses, item => { return item.name === status })?.id;
  }

  const handleDeactivate = () => {
    PartnerHandler().deactivate(partnerSlug)
      .then(() => {
        PartnerHandler().get(getSlug());
        displayMiniFeedback("Partner deactivated");
        dispatch(popUpActions.closePopup());
      });
  }

  const handleActivation = () => {
    PartnerHandler().approve(partnerSlug)
      .then(() => {
        PartnerHandler().get(getSlug());
        displayFeedback({
          title: `Success!`,
          body: `You have successfully activated ${partner?.name}!`,
          type: "PARTNER"
        } as IFeedback);
      });
  }

  let cardStyle = "";
  let card2Style = "";

  if (variant === "accountInformation") {
    cardStyle = styles.cardMerchantAccountTab;
    card2Style = styles.card2MerchantAccountTab;
  } else if (variant === "users") {
    cardStyle = styles.cardUsersLocationsTab;
  } else if (variant === "experienceSettings") {
    cardStyle = styles.cardExperienceSettingsTab;
    card2Style = styles.card2ExperienceSettingsTabPartner;
  }

  const isForPartner = () => {
    return isForPartnerGlobal(user, partnerSlug);
  }

  const editButton = (id, onClick) => {
    return <>
      {onClick && <div className={styles.editInfo}>
        <Edit
          color="var(--primaryTextColor)"
          size={22}
          onClick={onClick}
          id={`${id}_edit_button`}
        />
      </div>}
    </>
  }

  const getPartnerInfo = () => {
    return <>
      <LineWithIcon icon={Location} text={concatenateAddress(address) || "-"} />
      <LineWithIcon icon={Global} text={partner?.website || "-"} />
      {/* <LineWithIcon icon={InfoCircle} text={partner?.id} rotate180deg={true} /> */}
      {(user?.user_type === "WHITELABEL") &&
        <LineWithIcon icon={Tag} text={partner?.external_id || "-"} />
      }
      <LineWithIcon icon={Shop} text={(partnerDetailsLabels?.merchant_number || "-")} />
      <LineWithIcon icon={ProfileCircle} text={(partner?.main_contact_first_name || partner?.main_contact_last_name) ? (partner?.main_contact_first_name || "") + " " + (partner?.main_contact_last_name || "") + " (main contact)" : "-"} />
      <LineWithIcon icon={Call} text={formatPhone(partner?.main_contact_phone) || "-"} />
      <LineWithIcon icon={SmsEdit} text={partner?.main_contact_email || "-"} />
      <LineWithIcon icon={UserCirlceAdd} text={partnerDetailsLabels?.type || "-"} />
      <LineWithIcon icon={Calendar} text={partner?.last_referral_at ? formatAPIDate(partner?.last_referral_at) + " (last referral)" : "-"} />
    </>
  };

  const handleMoreInfoClick = () => {
    if (getSlug()) {
      Analytics.track({ experience: "portal", screen: "partners", object: "more_information_button", action: "clicked" } as ITracking, { partner_id: partner?.id });
      navigate(`/viewPartner/${getSlug()}/accountInformation`);
    }
  }

  return <>
    {variant === "partners" && <>
      <div id="merchantSummaryWrapper" className={active ? "merchantSummaryActive" : ""} ref={ref}>
        <div className={styles.cardsWrapper + " " + styles.merchantInformationCard}>
          <div className={styles.card + " " + cardStyle}>
            <CloseIcon id="applicationSummary" color="dark" onClose={closePartnerSummary} />
            <h2 style={{ color: "var(--primaryVariationTextColor)" }}>{partner?.name}</h2>
            {getPartnerInfo()}
          </div>
          <div className={styles.card2 + " " + card2Style}>
            <h2 style={{ color: "var(--primaryVariationTextColor)" }}>Their Merchants</h2>
            <LineWithIcon icon={Shop} text={"Active merchants • "} textPrimary={formatAmount(partner?.active_merchants_rate * 100 || 0) + "%"} />
            <LineWithIcon icon={MoneyTick} text={"Approval rate • "} textPrimary={formatAmount(partner?.approval_rate * 100 || 0) + "%"} />
            <LineWithIcon icon={CardTick} text={"Conversion rate • "} textPrimary={formatAmount(partner?.conversion_rate * 100 || 0) + "%"} />
            <div className={styles.moreInfoWrapper}>
              <Button
                id="btn_moreInformation"
                label="More information"
                onClick={handleMoreInfoClick}
                className={styles.moreInfoButton}
              />
            </div>
          </div>
        </div>
      </div>
    </>}

    {variant === "accountInformation" && <>
      <div className={styles.cardsWrapper2}>
        <div className={styles.cardsWrapper + " " + styles.merchantInformationCard}>
          <div className={styles.card + " " + cardStyle + (isPartnerDeactivated ? " " + styles.disabled : "")}>
            {editButton(variant + "_editBusinessAndMainContact",
              () => dispatch(popUpActions.openPopup(POPUPS.EDIT_PARTNER_BUSINESS_AND_MAIN_CONTACT))
            )}
            <h2 style={{ color: "var(--primaryVariationTextColor)" }}>{partner?.name + (isPartnerDeactivated ? " (deactivated)" : "")}</h2>
            {getPartnerInfo()}

            <div className={styles.buttons}>
              <Button
                id="btn_history"
                label="History"
                style={{ width: 160, height: 48, fontSize: 14, fontWeight: 600 }}
                onClick={handleHistoryClick}
              />

              {partner && partner?.status !== getStatusId("Pending") && user?.user_type === "WHITELABEL" && <>
                <Button
                  id="partner_information_card_deactivate_partner"
                  type="button"
                  label={partner?.status === getStatusId("Deactivated") || partner?.status === getStatusId("Declined") ? "Activate" : "Deactivate"}
                  onClick={() => {
                    if (partner?.status === getStatusId("Deactivated") || partner?.status === getStatusId("Declined")) {
                      askForConfirmation(`Are you sure you want to activate ${partner?.name}?`,
                        { text: 'Yes, I do', action: () => { dispatch(popUpActions.closePopup()); handleActivation() } },
                        { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
                    } else {
                      askForConfirmation(`Are you sure you want to deactivate ${partner?.name}?`,
                        { text: 'Yes, deactivate', action: () => { handleDeactivate() } },
                        { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
                    }
                  }}
                  variant="secondary"
                  style={{ width: 160, height: 48, fontSize: 14, fontWeight: 600 }}
                />
              </>}
            </div>

          </div>
        </div>
      </div>
    </>}

    {variant === "users" && <>
      <div className={styles.cardsWrapper2}>
        <div className={styles.cardsWrapper + " " + styles.merchantInformationCard}>
          <div className={styles.card + " " + cardStyle}>
            <h2 style={{ color: "var(--primaryVariationTextColor)" }}>{partner?.name} Associations</h2>

            <LineWithIcon icon={Home} text={"Created by " + formatFullname(partner?.created_by?.first_name, partner?.created_by?.last_name)} />
            <LineWithIcon icon={UserEdit} text={"Creator " + partner?.created_by?.account_name} />
            <LineWithIcon icon={Calendar} text={formatAPIDate(partner?.created_at)} />

          </div>
        </div>
      </div>
    </>}

    {variant === "experienceSettings" && <>
      <div className={styles.cardsWrapper2}>
        <div className={styles.cardsWrapper + " " + styles.merchantInformationCard}>
          <div className={styles.card + " " + cardStyle}>
            {editButton(variant + "_logoSettings",
              () => dispatch(popUpActions.openPopup(POPUPS.LANDING_PAGE_LOGO_SETTINGS))
            )}

            <h2 style={{ color: "var(--primaryVariationTextColor)" }}>{partner?.name} Logo</h2>
            {isForPartner() && partner?.primary_logo && <img className={styles.logo} src={partner?.primary_logo} alt={partner?.name} />}

          </div>
          <div className={styles.card2 + " " + card2Style}>
            {editButton(variant + "_urlSettings",
              () => dispatch(popUpActions.openPopup(POPUPS.LANDING_PAGE_URL_SETTINGS))
            )}
            <h2 style={{ color: "var(--primaryVariationTextColor)" }}>Experience Settings</h2>
            <LineWithIcon icon={Bucket} text={"#" + whitelabel.primary_color + " (primary)"} />
            <LineWithIcon icon={Bucket} color="var(--secondaryColor)" text={"#" + whitelabel.secondary_color + " (secondary)"} />
            <LineWithIcon icon={Global} text={DOMAIN_PREFIX} textPrimary={getSlug()} />
          </div>
        </div>
      </div>
    </>
    }

  </>;
}

export default React.forwardRef(PartnerInformationCard);