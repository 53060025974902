import styles from "./TableContainer.module.scss";
import { Table, ExportButton, Link, FilterButton, Button, Breadcrumbs } from "common";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { IHeader, IList } from "common/interfaces";
import { useParams } from "react-router-dom";
import { POPUPS } from "utils/constants";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import appActions from "reducers/AppReducer";
import popUpActions from "reducers/PopUpReducer";
import { TableRef } from "common/Table";
import { MerchantHandler } from "actions/MerchantHandler";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import Observer, { EVENTS } from "classes/Observer";
import { Grid } from "@mui/material";
import Analytics, { ITracking } from "classes/Analytics";
import { formatAPICurrency, formatAPIDate, formatAPIPhone, formatAPIString, formatFullname } from "utils/formatters";

const MerchantApplications: React.FC = () => {
  const dispatch = useAppDispatch();
  const { merchantSlug } = useParams();
  const merchantApplicationFilter = useAppSelector(state => state.app.tableFilters.merchantApplication);
  const tableFilterAppliedObserver = Observer.useObserver(EVENTS.TABLE_FILTER_APPLIED);

  const [merchant, setMerchant] = useState<any>(null);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const tableRef = useRef<TableRef>(null);
  const [preventPagination, setPreventPagination] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(appActions.resetMerchantApplicationFilter());
    };
  }, []);

  useEffect(() => {
    if (merchantSlug) {
      MerchantHandler().get(merchantSlug)
        .then(response => {
          setMerchant(response);
        });
    }
  }, [merchantSlug]);

  useEffect(() => {
    tableRef.current.reloadData();
  }, [merchantApplicationFilter, tableFilterAppliedObserver]);

  const getListApplications = async (next: string): Promise<IList> => {
    setLoadingData(true);
    let applications = await LoanApplicationHandler.getMany(next, { ...merchantApplicationFilter, merchant: merchantSlug }, preventPagination, merchantSlug);
    applications.results = applications.results.map(result => {
      const location = formatAPIString(result.merchant?.name);
      return {
        name: <Link
          id={`application_${result.id}`}
          href={`/viewApplicant/${result.id}/application?bc=1`}
          onClick={() => {
            Analytics.track({ experience: "portal", screen: "merchants_applications", object: "application_record", action: "selected" } as ITracking, { application_id: result.id, merchant_id: result.merchant?.id });
          }}
          linkText={formatFullname(result?.first_name, result?.last_name)} />,
        phone: formatAPIPhone(result.phone_number),
        email: formatAPIString(result.email),
        location: <Link id={`viewMerchant_${result.id}`} href={`/viewMerchant/${result.merchant?.slug}/accountInformation`} linkText={location} />,
        status: result.status,
        appdate: formatAPIDate(result.created_at),
        requested: formatAPICurrency(result.loan_amount),
        funded: formatAPICurrency(result.funded_amount),
        external_id: result?.external_id || ""
      };
    });
    setLoadingData(false);
    return Promise.resolve(applications);
  }

  const applicationsHeaders: Array<IHeader> = [
    { label: "Name", value: "name", size: 10 },
    { label: "Phone", value: "phone", size: 9 },
    { label: "Email", value: "email", size: 13 },
    { label: "Merchant", value: "location", size: 13, sortBy: "location__name" },
    { label: "Status", value: "status", size: 8 },
    { label: "App date", value: "appdate", size: 8, sortBy: "created_at" },
    { label: "Requested", value: "requested", size: 8, align: 'right' },
    { label: "Funded", value: "funded", size: 8, align: 'right' },
  ];

  useEffect(() => {
    if (preventPagination) {
      tableRef?.current?.reloadData();
    }
  }, [preventPagination]);

  useEffect(() => {
    if (preventPagination) {
      window.DownloadCSV(tableData, [...applicationsHeaders, { label: "External ID", value: "external_id" }] as Array<any>, "Applications.csv");
      setPreventPagination(false);
    }
  }, [tableData]);

  const exportAction = () => {
    setPreventPagination(true);
  }

  return <div className={styles.contentContainer}>
    <Container fluid>
      {<div className={styles.titleContainer}>
        <Grid container>
          <Grid item sm={12} md={3} lg={4} style={{ display: "flex", alignItems: "center", height: "100%" }}>
            {merchant?.name && <Breadcrumbs pageTitle={`${merchant?.name} applications`} />}
          </Grid>
          <Grid item sm={12} md={9} lg={8} style={{ textAlign: "right" }}>
            <Button
              id="merchantApplications_invitationStatus"
              label="Invitation status"
              variant="secondary"
              onClick={() => {
                Analytics.track({ experience: "portal", screen: "merchants_applications", object: "invitation_status_button", action: "clicked" } as ITracking);
                dispatch(popUpActions.openPopup({ name: POPUPS.INVITATION_STATUS, message: "APPLICATIONS" }));
              }}
              style={{ marginRight: 20 }} />
            <FilterButton
              id="applications_filter"
              //style={{ marginLeft: "auto" }}
              onClick={() => {
                dispatch(popUpActions.openPopup({ name: POPUPS.TABLE_FILTER, message: { type: "MERCHANT_APPLICATIONS" } }));
              }}
              type="MERCHANT_APPLICATION"
              style={{ marginRight: 20 }}
            />
            <ExportButton
              id="merchantApplications"
              onClick={() => {
                exportAction();
                Analytics.track({ experience: "portal", screen: "merchants_applications", object: "export_button", action: "clicked" } as ITracking);
              }}
              exporting={preventPagination}
              disabled={loadingData}
            />
          </Grid>
        </Grid>
      </div>}
    </Container>
    <Container fluid className={styles.tableContainer}>
      <Table
        id="applications_location"
        data={getListApplications}
        headers={applicationsHeaders}
        onUpdate={setTableData}
        tableBodyStyle={{ minWidth: 1200 }}
        ref={tableRef}
        maxHeight={450}
      />
    </Container>
  </div >;
};

export default MerchantApplications;
