import React from "react";
import styles from "./Color.module.scss";

interface IColor {
  /** 
    * @summary CSS color code.
    * */
  colorCode: string;
}

const Color: React.FC<IColor> = ({ colorCode }) => {
  const color = colorCode.indexOf('#') > -1 ? colorCode : `#${colorCode}`;
  return <>
    <span style={{ color: "var(--darkTextColor)" }}>{color.toUpperCase()}</span>
    <img src="/assets/images/blank.png" className={styles.img} style={{ backgroundColor: color }} />
  </>;
};

export default Color;
