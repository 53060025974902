import { InfoContainer } from "common";
import React from "react";
import popUpActions from "reducers/PopUpReducer";
import { useAppDispatch } from "reducers/Hooks";
import { POPUPS } from "utils/constants";
import { Shop, WalletCheck } from "iconsax-react";

interface ISupportInformationCard {
  type: "MERCHANT_HELP" | "BORROWER_HELP";
  contactName: string;
  email: string;
  phone: string;
}

const SupportInformationCard: React.FC<ISupportInformationCard> = ({ type, contactName, email, phone }) => {
  const dispatch = useAppDispatch();

  return <>
    <InfoContainer
      id={`${type.toLowerCase()}_support_card`}
      title={type === "MERCHANT_HELP" ? "Merchant help contact" : "Borrower help contact"}
      rows={[
        { label: "Contact name", value: contactName },
        { label: "Email", value: email },
        { label: "Phone", value: phone },
      ]}
      onClick={() => {
        if (type === "MERCHANT_HELP") {
          dispatch(popUpActions.openPopup({ name: POPUPS.EDIT_SUPPORT, message: "MERCHANT" }));
        } else {
          dispatch(popUpActions.openPopup({ name: POPUPS.EDIT_SUPPORT, message: "BORROWER" }));
        }
      }}
      icon={type === "MERCHANT_HELP" ? Shop : WalletCheck}
    />
  </>;
}

export default SupportInformationCard;