import styles from "./Settings.module.scss";
import React, { useEffect, useState } from "react";
import popUpActions from "reducers/PopUpReducer";
import { useAppDispatch } from "reducers/Hooks";
import { Breadcrumbs, Button, Grid, InfoContainer } from "common";
import BusinessInformationCard from "./accountInformation/BusinessInformationCard";
import MainContactCard from "./accountInformation/MainContactCard";
import { IAddress, IFeedback } from "common/interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { POPUPS } from "utils/constants";
import { askForConfirmation, displayFeedback, displayMiniFeedback, getAllStatuses } from "utils/helpers";
import { useAuth } from "auth/useAuth";
import UserAccountCard from "./accountInformation/UserAccountCard";
import _ from "lodash";
import Observer, { EVENTS } from "classes/Observer";
import { MerchantHandler } from "actions/MerchantHandler";
import WhiteLabelHandler from "actions/WhiteLabelHandler";
import { useFlag } from "hooks/useFlag";
import { Col, Row } from "react-bootstrap";
import { UserOctagon, Setting3 } from "iconsax-react";
import { formatAPICurrency, formatFullname } from "utils/formatters";
import useMerchant from "hooks/useMerchant";

const MerchantAccountInformation: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAuth()?.user;
  const { merchantSlug } = useParams();
  const merchant = useMerchant();
  const merchantUpdated = Observer.useObserver(EVENTS.MERCHANT_UPDATED);
  const [merchantStatuses, setMerchantStatuses] = useState([]);
  const [lenderGroups, setLenderGroups] = useState(null);
  const enablePartners = useFlag("partners");

  useEffect(() => {
    if (window.location.pathname.toLowerCase() === "/settings/experiencesettings") {
      navigate('/settings/accountInformation');
    }
    getAllStatuses()
      .then(response => {
        setMerchantStatuses(response);
      });

    WhiteLabelHandler.getLenderGroups()
      .then(response => {
        setLenderGroups(response);
      });
  }, []);

  useEffect(() => {
    MerchantHandler().get(getSlug());
  }, [merchantUpdated]);

  const getSlug = () => {
    return merchantSlug || merchant?.slug;
  }

  const handleDeactivate = () => {
    MerchantHandler().deactivate(merchantSlug)
      .then(() => {
        MerchantHandler().get(getSlug());
        displayMiniFeedback("Merchant deactivated");
        dispatch(popUpActions.closePopup());
      });
  }

  const handleActivation = () => {
    MerchantHandler().approve(merchantSlug)
      .then(() => {
        MerchantHandler().get(getSlug());
        displayFeedback({
          title: `Success!`,
          body: `You have successfully activated ${merchant?.name}!`,
          type: "MERCHANT"
        } as IFeedback);
      });
  }

  const handleApprove = () => {
    MerchantHandler().approve(merchantSlug)
      .then(() => {
        MerchantHandler().get(getSlug());
        displayFeedback({
          title: `You have successfully approved ${merchant?.name}!`,
          body: `Their account will now become active and the main contact will get an email to create a user account.`,
          type: "MERCHANT"
        } as IFeedback);
      });
  }

  const handleDecline = (reason: string): void => {
    MerchantHandler().decline(merchantSlug, reason)
      .then(() => {
        MerchantHandler().get(getSlug());
        displayMiniFeedback(`You have successfully declined ${merchant?.name}!`);
        dispatch(popUpActions.closePopup());
      });
  }

  const getStatusId = (status: "Active" | "Pending" | "Deactivated" | "Declined"): number => {
    return _.find(merchantStatuses, item => { return item.name === status })?.id;
  }

  const getAssociationsRows = (): Array<any> => {
    let rows = [
      { label: "Created by (user)", value: formatFullname(merchant?.created_by?.first_name, merchant?.created_by?.last_name) }
    ];
    if (enablePartners) {
      rows.push({ label: "Created by (account)", value: merchant?.created_by?.account_name || "-" });
    }
    return rows;
  }

  const isDisabled = (): boolean => {
    return merchant?.status === getStatusId("Deactivated");
  }

  const getTitle = () => {
    let pageTitle = merchant?.name;
    if (user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") {
      pageTitle += merchant?.status === getStatusId("Deactivated") ? " (deactivated) " : (merchant?.status === getStatusId("Declined") ? " (declined) " : (merchant?.status === getStatusId("Pending") ? " (pending) " : " "));
    }
    pageTitle += "account information";
    return pageTitle;
  }

  return <>
    {merchant && merchant?.status === getStatusId("Pending") && user?.user_type === "WHITELABEL" && <div className={styles.approval}>
      <p className={styles.approvalText}>This merchant requires approval</p>
      <Button id="merchantAccountInformation_btn_approve" label="Approve" style={{ padding: "1rem" }} onClick={handleApprove} />
      <Button id="merchantAccountInformation_btn_deny" label="Deny" style={{ padding: "1rem" }} variant="secondary" onClick={() => {
        dispatch(popUpActions.openPopup({
          name: POPUPS.DECLINE_MERCHANT,
          message: {
            merchant: merchant,
            onSend: handleDecline
          }
        }))
      }} />
    </div>}
    {merchant && <div className={styles.contentContainer}>
      <Row>
        <Col md={6} lg={6} className={styles.accountInformationTitle}>
          {merchant?.name && <Breadcrumbs pageTitle={getTitle()} />}
        </Col>
        <Col md={6} lg={6}>
          {merchant && merchant?.status !== getStatusId("Pending") && user?.user_type === "WHITELABEL" && <>
            <div style={{
              display: "flex",
              justifyContent: "end",
            }}>
              <Button
                id="merchant_account_information_deactivate_merchant"
                type="button"
                label={merchant?.status === getStatusId("Deactivated") || merchant?.status === getStatusId("Declined") ? "Activate" : "Deactivate"}
                onClick={() => {
                  if (merchant?.status === getStatusId("Deactivated") || merchant?.status === getStatusId("Declined")) {
                    askForConfirmation(`Are you sure you want to activate ${merchant?.name}?`,
                      { text: 'Yes, I do', action: () => { dispatch(popUpActions.closePopup()); handleActivation() } },
                      { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
                  } else {
                    askForConfirmation(`Are you sure you want to deactivate ${merchant?.name}?`,
                      { text: 'Yes, deactivate', action: () => { handleDeactivate() } },
                      { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
                  }
                }}
                variant="secondary"
                style={{ width: "auto" }}
              />
            </div>
          </>}
        </Col>
      </Row>
      <Grid horizontal>
        <Grid>
          <Grid item>
            <BusinessInformationCard
              id="merchant_account_information"
              externalId={merchant?.external_id}
              businessName={merchant?.name}
              mainAddress={{
                address1: merchant?.address1,
                address2: merchant?.address2 || "",
                city: merchant?.city,
                state: merchant?.state,
                postal_code: merchant?.postal_code
              } as IAddress}
              website={merchant?.website}
              type="MERCHANT"
              annualSales={merchant?.annual_sales}
              estMonthlyFinanceVolume={merchant?.monthly_financing_volume}
              averageTicket={merchant?.average_ticket}
              industry={merchant?.industry}
              specialty={merchant?.industry_specialty}
              disabled={isDisabled()}
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid item>
            <MainContactCard
              cardTitle="Main contact information"
              firstName={merchant?.main_contact_first_name}
              lastName={merchant?.main_contact_last_name}
              phone={merchant?.main_contact_phone}
              email={merchant?.main_contact_email}
              disabled={isDisabled()}
            />
          </Grid>
          {(user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") && <Grid item>
            <InfoContainer
              id="associations_card"
              title="Associations"
              rows={getAssociationsRows()}
              disabled={isDisabled()}
              icon={UserOctagon}
            />
          </Grid>}
          {user && user?.user_type === "MERCHANT" && <Grid item>
            <UserAccountCard />
          </Grid>
          }
          {(user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") && lenderGroups && <Grid item>
            <InfoContainer
              id="program_settings_card"
              title="Program settings"
              rows={[
                { label: "Lender group", value: lenderGroups.length === 0 ? "Default lender group" : (merchant?.lender_group?.name || lenderGroups.find(item => { return item.default })?.name || "Default lender group") },
                { label: "Max loan amount", value: formatAPICurrency(merchant?.max_loan_amount, false) },
              ]}
              onClick={(user?.user_type === "PARTNER" && merchant?.status !== getStatusId("Declined")) ? undefined : () => { dispatch(popUpActions.openPopup(POPUPS.EDIT_LENDER_GROUP)) }}
              disabled={isDisabled()}
              icon={Setting3}
            />
          </Grid>}
          {user?.user_type === "MERCHANT" && <Grid item>
            <InfoContainer
              id="program_settings_card"
              title="Program settings"
              rows={[
                { label: "Max loan amount", value: formatAPICurrency(merchant?.max_loan_amount, false) },
              ]}
              disabled={isDisabled()}
              icon={Setting3}
            />
          </Grid>}
        </Grid>
      </Grid>
    </div>}
  </>;
};

export default MerchantAccountInformation;
