import { UnderwritingHandler } from "actions/UnderwritingHandler";
import { SVG } from "common";
import { EmptySpace, Form, UploadFile } from "common/form";
import { IAddEntity, IDTMOwner } from "common/interfaces";
import React, { useEffect, useState } from "react";
import dtmApplicationActions from "reducers/DTMApplicationReducer";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { isNullOrUndefined } from "utils/helpers";
import { Schema, SimpleRequireValidation } from "utils/validation/additionalValidation";

const Documents: React.FC<IAddEntity> = ({ onNext, initialValues }) => {
  const dispatch = useAppDispatch();
  const ownerIDsToKeep = useAppSelector(state => state.dtmApplication.ownerIDsToKeep);
  const docs = useAppSelector(state => state.dtmApplication.docs);
  const [parsedInitialValues, setParsedInitialValues] = useState(null);

  useEffect(() => {
    setParsedInitialValues({
      ...initialValues,
      ...docs
    });
  }, [initialValues, docs]);

  const onFileSelection = (file: any, name: string) => {
    dispatch(dtmApplicationActions.addDoc({ key: name, doc: file?.name }));
    UnderwritingHandler().uploadDocument(initialValues?.id, name, file);
  }

  const saveData = (data: any) => {
    UnderwritingHandler().getDTMOwners(initialValues?.id)
      .then((owners: Array<IDTMOwner>) => {
        const existingOwners = owners.map(owner => owner.id);
        const ownersToDelete = existingOwners.filter(owner => !ownerIDsToKeep.includes(owner));
        const deletionPromises: Array<Promise<any>> = [];
        ownersToDelete.forEach(ownerToDelete => {
          deletionPromises.push(UnderwritingHandler().deleteDTMOwner(initialValues?.id, ownerToDelete));
        });
        Promise.all(deletionPromises)
          .then(() => {
            onNext(data);
          });
      });
  }

  return <>
    {isNullOrUndefined(parsedInitialValues) && <div style={{ paddingTop: 50, paddingBottom: 50 }}>
      <SVG src="/assets/images/loading.svg" width="50" height="50" />
    </div>}
    {!isNullOrUndefined(parsedInitialValues) && <Form
      id="dtm_application_documents"
      title="Business documents"
      submitBtnText="Submit application"
      onFormSubmit={(data: any) => { saveData(data); }}
      preface={[
        "To be considered for all DTM lenders, you must upload every document listed below."
      ]}
      prefaceInfo="To be considered for all DTM lenders, it's important to provide all documents listed on this page. The minimum required documents to be considered for DTM is indicated by an asterisk(*). If you only upload the minimum documents, you will not be considered for some of our sub-prime lenders."
      validationSchema={Schema({
        doc_voided_check: SimpleRequireValidation,
        doc_refund_policy: SimpleRequireValidation,
        doc_incorporation_articles: SimpleRequireValidation,
        doc_ein_letter: SimpleRequireValidation,
        doc_monthly_statement_1: SimpleRequireValidation,
        doc_monthly_statement_2: SimpleRequireValidation,
        doc_monthly_statement_3: SimpleRequireValidation
      })}
      values={parsedInitialValues}
      buttonPosition="side right"
      inputFields={[
        [
          <UploadFile name="doc_voided_check" id="dtm_application_doc_voided_check" label="Voided check*" onFileSelection={onFileSelection} displayLogo={false} filename={parsedInitialValues?.doc_voided_check} />,
          <UploadFile name="doc_refund_policy" id="dtm_application_doc_refund_policy" label="Refund policy*" onFileSelection={onFileSelection} displayLogo={false} filename={parsedInitialValues?.doc_refund_policy} />,
        ],
        [
          <UploadFile name="doc_incorporation_articles" id="dtm_application_doc_incorporation_articles" label="Article of incorporation*" onFileSelection={onFileSelection} displayLogo={false} filename={parsedInitialValues?.doc_incorporation_articles} />,
          <UploadFile name="doc_ein_letter" id="dtm_application_doc_ein_letter" label="EIN assignment letter or other proof of EIN*" onFileSelection={onFileSelection} displayLogo={false} filename={parsedInitialValues?.doc_ein_letter} />,
        ],
        [
          <UploadFile name="doc_monthly_statement_1" id="dtm_application_doc_monthly_statement_1" label="Last 3 months bank statements (part 1)*" onFileSelection={onFileSelection} displayLogo={false} filename={parsedInitialValues?.doc_monthly_statement_1} />,
          <UploadFile name="doc_monthly_statement_2" id="dtm_application_doc_monthly_statement_2" label="Last 3 months bank statements (part 2)*" onFileSelection={onFileSelection} displayLogo={false} filename={parsedInitialValues?.doc_monthly_statement_2} />,
        ],
        [
          <UploadFile name="doc_monthly_statement_3" id="dtm_application_doc_monthly_statement_3" label="Last 3 months bank statements (part 3)*" onFileSelection={onFileSelection} displayLogo={false} filename={parsedInitialValues?.doc_monthly_statement_3} />,
          <UploadFile name="doc_year_profit_and_loss" id="dtm_application_doc_year_profit_and_loss" label="Last 12 months profit and loss (financials)" onFileSelection={onFileSelection} displayLogo={false} filename={parsedInitialValues?.doc_year_profit_and_loss} />,
        ],
        [
          <UploadFile name="doc_year_balance_sheet" id="dtm_application_doc_year_balance_sheet" label="Last 12 months balance sheet (financials)" onFileSelection={onFileSelection} displayLogo={false} filename={parsedInitialValues?.doc_year_balance_sheet} />,
        ],
      ]}
      isInsidePopup
    />}
  </>;
}

export default Documents;