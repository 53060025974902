import styles from "./Settings.module.scss";
import { Color, Grid, InfoContainer } from "common";
import React, { CSSProperties } from "react";
import popUpActions from "reducers/PopUpReducer";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { POPUPS } from "utils/constants";
import { GlobalEdit } from "iconsax-react";

const Experience: React.FC = () => {
  const dispatch = useAppDispatch();
  const whitelabel = useAppSelector(state => state.whitelabel);
  const subdomain = useAppSelector(state => state.whitelabel?.slug);
  const domain = `${subdomain || "{whitelabel}"}${window.location.host.substring(window.location.host.indexOf('.'))}`;
  const logo = whitelabel?.primary_logo || null;
  const logo2 = whitelabel?.secondary_logo || null;

  const imgStyle: CSSProperties = {
    height: "auto",
    width: "auto",
    maxWidth: "80%",
    maxHeight: 100,
    objectFit: "cover"
  };

  return <div className={styles.contentContainer}>
    <Grid horizontal>
      <Grid item>
        <InfoContainer
          id="experience_settings_card"
          title="Experience settings"
          icon={GlobalEdit}
          rows={[
            { label: "Primary color", value: <Color colorCode={whitelabel?.primary_color} /> },
            { label: "Secondary color", value: <Color colorCode={whitelabel?.secondary_color} /> },
            { label: "Subdomain prefix", value: domain },
            //{ label: "Email name", value: `notifications@${domain}` }, // TODO: not for MVP
            { label: "Allow merchant logos on landing pages?", value: whitelabel?.display_merchant_logo ? "Yes" : "No" },
            // { label: "Allow partner logos on landing pages?", value: whitelabel?.display_partner_logo ? "Yes" : "No" },
          ]}
          style={{ height: "auto" }}
        />
      </Grid>
      <Grid item>
        <Grid>
          <Grid item>
            <InfoContainer
              icon={null}
              id="client_portal_logo_card"
              backgroundColor="var(--primaryColor)"
              onClick={() => dispatch(popUpActions.openPopup(POPUPS.LANDING_PAGE_SETTINGS))}>
              <div style={{ textAlign: "center" }} >
                <h3 style={{ color: "white", paddingBottom: "2rem" }} >Client portal logo</h3>
                <img src={logo || ""} style={imgStyle} alt="custom logo" />
              </div>
            </InfoContainer>
          </Grid>
          <Grid item>
            <InfoContainer
              icon={null}
              id="landing_page_logo_card">
              <div style={{ textAlign: "center", color: "var(--primaryVariationTextColor)" }}>
                <h3 style={{ paddingBottom: "2rem" }}>Landing page logo</h3>
                <img src={logo2 || ""} style={imgStyle} alt="custom logo" />
              </div>
            </InfoContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </div >;
};

export default Experience;
