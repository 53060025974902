import styles from "./Sidebar.module.scss";
import { useAuth } from "auth/useAuth";
import { POPUPS, sidebars } from "utils/constants";
import SidebarMenuItem from "./SidebarMenuItem";
import { useMemo, useState } from "react";
import { useAppSelector } from "reducers/Hooks";
import { Hidden } from "@mui/material";
import PoweredByEnable from "./PoweredByEnable";
import { ISidebarItem } from "common/interfaces";
import React from "react";
import { useFlag } from "hooks/useFlag";
import { Add, ArrowCircleRight } from "iconsax-react";
import Link from "common/Link";
import Analytics, { ITracking } from "classes/Analytics";
import { AppIcon, Button } from "common";
import popUpActions from "reducers/PopUpReducer";
import { useDispatch } from "react-redux";
import LocalStorage from "classes/LocalStorage";
import useUnderwritingApplications from "hooks/useUnderwritingApplications";
import DTMAppStatusEnum from "common/enums/DTMAppStatusEnum";

const Sidebar: React.FC = () => {
  const user = useAuth()?.user;
  const whitelabel = useAppSelector(state => state.whitelabel);
  const state = useAppSelector(state => state);
  const logo = state.whitelabel?.primary_logo || "";
  const sidebarItems = useMemo(() => {
    return sidebars[user?.user_type];
  }, [user]);
  const enablePartners = useFlag("partners");
  const dispatch = useDispatch();
  const dtmFlowEnabled = useFlag("underwriting-dtm-flow");
  const [dtmButtonClosed, setDtmButtonClosed] = useState(LocalStorage.get<boolean>("dtm_button_closed"));
  const [dtmAppStatus, verified, declined, buttonLabel] = useUnderwritingApplications();

  const handleBusinessLendingClick = () => {
    Analytics.track({ experience: "portal", screen: "portal_sidebar", object: "business_lending_link", action: "clicked" } as ITracking);
  }

  const handleDTMButtonClose = () => {
    LocalStorage.save("dtm_button_closed", true);
    setDtmButtonClosed(true);
  }

  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.siteLogo}>
        <img src={logo} alt="company logo" />
      </div>
      <div className={styles.menuContainer}>
        <ul>
          {sidebarItems && sidebarItems.map((item: ISidebarItem, index: number) => {
            const key = `menu-item-${index}`;
            if (item.href === "/partners" && !enablePartners) {
              return <React.Fragment key={key}></React.Fragment>;
            } else {
              return <SidebarMenuItem
                id={`${key}_sideMenuItem`}
                key={key}
                href={item.href}
                label={item.label}
                icon={item.icon}
                onClick={item.onClick} />;
            }
          })}
        </ul>
      </div>
      {whitelabel?.business_lending_enabled && whitelabel?.business_lending_url && user?.user_type === "MERCHANT" && <Hidden lgDown>
        <div className={styles.financeBusinessBackground}>
          <div className={styles.financeBusiness}>
            <Link id="business_lending" href={whitelabel?.business_lending_url} onClick={handleBusinessLendingClick}>
              <p>Finance your business </p>
              <ArrowCircleRight size={16} />
            </Link>
          </div>
        </div>
      </Hidden>
      }
      {dtmFlowEnabled && verified && !declined && !dtmButtonClosed && ![DTMAppStatusEnum.NOT_ELIGIBLE, DTMAppStatusEnum.UNDER_REVIEW].includes(dtmAppStatus) && <Hidden lgDown>
        <div className={styles.dtmProcessBackground}>
          <div className={styles.dtmProcess}>
            <Button style={{ backgroundColor: "transparent", border: "none", borderRadius: "0px", alignItems: "center", marginLeft: "-30px", padding: "5px 0px", marginTop: "10px" }} id="sidebar_dtm_process" label={buttonLabel} onClick={() => {
              dispatch(popUpActions.openPopup(POPUPS.DTM_PROCESS));
            }} />
            <AppIcon
              clickTrigger={{
                id: `sidebar_dtmProcess_close`,
                onClick: handleDTMButtonClose
              }}
              color={`${"var(--whiteTextColor)"}`}
              size={35}
              className={styles.dtmProcessCloseButton}
              icon={Add}
              style={{ marginTop: "-2px" }}
            />
          </div>
        </div>
      </Hidden>
      }
      <Hidden mdDown>
        <div className={styles.bottomLogo}>
          <PoweredByEnable darkMode={whitelabel?.dark_mode} />
        </div>
      </Hidden>
    </div >
  );
};

export default Sidebar;
