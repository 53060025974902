import { AppIcon, Button } from "common";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IDTMApplication, IPopup } from "common/interfaces";
import {
  displayMiniFeedback, isAddressTheSame, isNullOrUndefined,
  loadDtmDocs, removeFieldsWithOnlyAsterisks, tryParseNumber
} from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import {
  StartDTMApplication, BusinessInformation, BankInformation, Documents, MainAddress,
  MainContactInformation, OwnerInformation, SalesInformation,
  EndDTMApplication
} from "./";
import { Add, ArrowLeft } from "iconsax-react";
import NewPopup from "../NewPopup";
import styles from "../AddEntityPopup.module.scss";
import { UnderwritingHandler } from "actions/UnderwritingHandler";
import { UW_APP_STATUSES } from "utils/constants";
import dtmApplicationActions from "reducers/DTMApplicationReducer";

// TODO: Add page tracking
const DTMApplicationPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const dtmOwnerPosition = useAppSelector(state => state.dtmApplication.ownerPosition);
  const dtmOwnersNumber = useAppSelector(state => state.dtmApplication.ownersNumber);
  const [step, setStep] = useState(0);
  const [displayConfirmation, setDisplayConfirmation] = useState<boolean>(false);
  const [dtmApplication, setDTMApplication] = useState<IDTMApplication>(null);
  const [isLegalAddressSame, setIsLegalAddressSame] = useState<boolean>(false);
  const applicationID = useRef<string>(null);
  const newApplication: IDTMApplication = { business_name: "", business_dba_name: "", business_phone: "", business_email: "", business_type: "", business_website: "", business_state_incorporated: "", business_start_date: "", business_ein: "", sales_last_year_annual_sales: "", sales_avg_ticket: "", sales_estimated_monthly_volume: "", sales_refund_policy: "", business_description: "", business_percent_customer_sales: "", business_percent_business_sales: "", business_percent_government_sales: "", business_address1: "", business_address2: "", business_city: "", business_state: "", business_zip_code: "", legal_address1: "", legal_address2: "", legal_city: "", legal_state: "", legal_zip_code: "", contact_first_name: "", contact_last_name: "", contact_email: "", contact_phone: "", bank_billing_contact_name: "", bank_billing_contact_phone: "", bank_name: "", bank_state: "", bank_account_number: "", bank_routing_number: "", bank_phone_number: "", bank_fees_withdrawn_from_account: "", doc_voided_check: "", doc_refund_policy: "", doc_incorporation_articles: "", doc_ein_letter: "", doc_monthly_statement_1: "", doc_monthly_statement_2: "", doc_monthly_statement_3: "", doc_year_profit_and_loss: "", doc_year_balance_sheet: "", sales_industry: "" };

  useEffect(() => {
    setStep(0);
    UnderwritingHandler().getDTMApplication()
      .then(uwApp => {
        if (isNullOrUndefined(uwApp)) {
          setDTMApplication({ ...newApplication });
        } else {
          if (uwApp.underwriting_status === UW_APP_STATUSES.PENDING) {
            applicationID.current = uwApp.id;
            setDTMApplication(uwApp);
            loadDtmDocs(uwApp);
            UnderwritingHandler().getDTMOwners(uwApp.id)
              .then(owners => {
                dispatch(dtmApplicationActions.setOwnersNumber(owners.length || 1));
                dispatch(dtmApplicationActions.clearOwnerIDsToKeep());
              });
            setIsLegalAddressSame(isAddressTheSame({ address1: uwApp.business_address1, address2: uwApp.business_address2, city: uwApp.business_city, state: uwApp.business_state, zip_code: uwApp.business_zip_code }, { address1: uwApp.legal_address1, address2: uwApp.legal_address2, city: uwApp.legal_city, state: uwApp.legal_state, zip_code: uwApp.legal_zip_code }));
          } else {
            displayMiniFeedback("Your application has already been submitted and is currently under review.", false);
            dispatch(popUpActions.closePopup());
          }
        }
      });
  }, []);

  const handleBackButton = () => {
    if (step === 4 && dtmOwnerPosition > 1) {
      dispatch(dtmApplicationActions.setDtmOwnerPosition(dtmOwnerPosition - 1));
    } else if (step === 5 && dtmOwnersNumber === 0) {
      setStep(3);
    } else if (step > 0) {
      setStep(step - 1);
    }
  }

  const createNewApplicationIfNeeded = async () => {
    if (isNullOrUndefined(applicationID.current)) {
      const response = await UnderwritingHandler().createDTMApplication({} as IDTMApplication);
      setDTMApplication(response);
      applicationID.current = response.id;
    }
    return Promise.resolve();
  }

  const stepUp = async (data: any) => {
    if (data == null) {
      if (step === 0) {
        await createNewApplicationIfNeeded();
      }
      setStep(step + 1);
      return;
    }

    // remove already provided sensitive fields from data - they should be excluded from patch request
    removeFieldsWithOnlyAsterisks(data);

    // Documents is the final step
    const isFinalStep = step === 7;
    // If no data, it means it is in the presentation step. If step 4, it means Owner info which is being sent to the API inside the step.
    if ((isNullOrUndefined(data) && step !== 0) || step === 4) { setStep(step + 1); return; }
    // verify if more owners number was provided
    if (!isNullOrUndefined(data?.how_many_25_more)) { dispatch(dtmApplicationActions.setOwnersNumber(tryParseNumber(data?.how_many_25_more))); dispatch(dtmApplicationActions.clearOwnerIDsToKeep()); }
    // verify if a selection for legal address is the same as business address was provided
    if (!isNullOrUndefined(data?.is_legal_business_address_same)) {
      setIsLegalAddressSame(data?.is_legal_business_address_same === "true");
      // If legal address is the same as business address, delete legal address
      if (data?.is_legal_business_address_same === "true") {
        data["legal_address1"] = null;
        data["legal_address2"] = null;
        data["legal_city"] = null;
        data["legal_state"] = null;
        data["legal_zip_code"] = null;
      }
    }

    // update application
    const response = await UnderwritingHandler().updateDTMApplication(applicationID.current, data as IDTMApplication, isFinalStep);
    setDTMApplication({ ...dtmApplication, ...response });

    if (step === 3 && dtmOwnersNumber === 0) {
      // Since owners quantity equals 0 it skips the Owner Info step.
      setStep(5);
      return;
    }

    if (step < 4 && dtmOwnersNumber > 0) {
      // That means it is in a step before the owner info. We want to make sure it always start with the first owner.
      dispatch(dtmApplicationActions.setDtmOwnerPosition(1));
    } else if (step > 4 && dtmOwnersNumber > 0) {
      // When on a step after owner make sure the owner start on the last owner when moving back
      dispatch(dtmApplicationActions.setDtmOwnerPosition(dtmOwnersNumber));
    }

    // move user to the next step
    setStep(step + 1);
  }

  const renderStep = () => {
    const components = [
      <StartDTMApplication initialValues={null} onNext={stepUp} />,
      <BusinessInformation initialValues={dtmApplication} onNext={stepUp} />,
      <SalesInformation initialValues={dtmApplication} onNext={stepUp} />,
      <MainAddress initialValues={{ ...dtmApplication, is_legal_business_address_same: isLegalAddressSame.toString() }} onNext={stepUp} />,
      <OwnerInformation initialValues={dtmApplication} onNext={stepUp} />,
      <MainContactInformation initialValues={dtmApplication} onNext={stepUp} />,
      <BankInformation initialValues={dtmApplication} onNext={stepUp} />,
      <Documents initialValues={dtmApplication} onNext={stepUp} />,
      <EndDTMApplication initialValues={dtmApplication} onNext={() => { dispatch(popUpActions.closePopup) }} />
    ];

    return components[step] || null;
  }

  const closeForm = () => {
    if (applicationID.current) {
      UnderwritingHandler().deleteDTMApplication(applicationID.current);
    }
    setDTMApplication({} as IDTMApplication);
    setStep(0);
    setDisplayConfirmation(false);
    dispatch(popUpActions.closePopup());
  }

  return <NewPopup id="dtmApplicationPopup_modal" isInner={true} style={{
    maxWidth: "80rem", paddingBottom: 0, maxHeight: '80%'
  }}>
    <>
      {displayConfirmation && <div>
        <p className={styles.confirmation}>
          Closing the form without finalizing the application<br />will result in losing your progress
        </p>
        <div className={styles.buttons} style={{ marginBottom: "5rem" }}>
          <Button id="dtm_application_confirmLoss" label="Confirm loss" variant="secondary" onClick={closeForm} />
          <Button id="dtm_application_stayForm" label="Stay on form" onClick={() => { setDisplayConfirmation(false); }} />
        </div>
      </div>}
      <div style={{ display: displayConfirmation ? "none" : "block" }}>
        {step > 0 && step < 8 && <AppIcon
          size={26}
          color="var(--darkTextColor)"
          clickTrigger={{ id: "dtm_application_back", onClick: handleBackButton }}
          style={{ cursor: "pointer", position: "absolute", top: 20, left: 20, width: 40, height: 26 }}
          icon={ArrowLeft}
        />}
        {step > 0 && step < 8 && <p className={styles.stepLabel}>Step {step}/7</p>}
        {/* close icon */}
        <AppIcon clickTrigger={{ id: "dtm_application_popup", onClick: () => { setDisplayConfirmation(true); } }} color="var(--darkTextColor)" size={35} className={styles.crossIcon} icon={Add} />
        <div style={{ marginTop: -20 }}>
          {renderStep()}
        </div>
      </div>
    </>
  </NewPopup>;
};

export default DTMApplicationPopup;