import styles from "./Settings.module.scss";
import { Sidebar, DashboardHeader, Aside, ApplicationMenu } from "common";
import React, { useEffect, useState } from "react";
import { Notifications, PartnerAccountInformation, Users } from ".";
import { partnerAdminSettingsMenu } from "utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { handleInnerMenusBackButton } from "utils/helpers";
import InnerPopups from "content/popups/InnerPopups";
import { useFlag } from "hooks/useFlag";
import PartnerSettingsView from "./settings/partner/PartnerSettingsView";

const PartnerSettings: React.FC = () => {
  const navigate = useNavigate();
  const { view } = useParams();
  const [viewSettings, setViewSettings] = useState(view || "accountInformation");
  const newSettingsView = useFlag("new-partner-settings-view");
  
  useEffect(() => {
    return handleInnerMenusBackButton(partnerAdminSettingsMenu.map(item => { return item.href }), setViewSettings);
  }, []);

  return <main className={styles.settingsPageContainer}>
    <Sidebar />
    <Aside>
      <DashboardHeader />
      <InnerPopups />
      {newSettingsView && <PartnerSettingsView />}
      {!newSettingsView && <>
      {viewSettings === "accountInformation" && <PartnerAccountInformation />}
      {viewSettings === "notifications" && <Notifications />}
      {viewSettings === "users" && <Users />}
      <ApplicationMenu
        onClick={(val: string) => {
          setViewSettings(val);
          navigate(`/settings/${val}`);
        }}
        activeList={viewSettings}
        items={partnerAdminSettingsMenu}
      />
      </>}
    </Aside>
  </main>;
};

export default PartnerSettings;
