import styles from "./TableContainer.module.scss";
import { Breadcrumbs, Table, TimeZoneMessage } from "common";
import React from "react";
import { Container } from "react-bootstrap";
import { IHeader, IList } from "common/interfaces";
import { useParams } from "react-router-dom";
import useMerchant from "hooks/useMerchant";
import { MerchantHandler } from "actions/MerchantHandler";
import { formatAPIDate } from "utils/formatters";

const MerchantHistory: React.FC = () => {
  const { merchantSlug } = useParams();
  const merchant = useMerchant(merchantSlug);

  const getHistory = async (next: string): Promise<any> => {
    let history: IList = await MerchantHandler().getHistory(next, merchantSlug);
    history.results = history.results.map(result => {
      return {
        ...result,
        date_time: formatAPIDate(result.date_time, true, true)
      }
    });
    return history;
  }

  const historyHeaders: Array<IHeader> = [
    { label: "Event", value: "event", size: 10, preventSorting: true },
    { label: "Date / Time", value: "date_time", size: 9 },
    { label: "Account", value: "account", size: 13, preventSorting: true },
    { label: "User", value: "user", size: 13, preventSorting: true },
  ];

  return <div className={styles.contentContainer}>
    <Container fluid>
      {merchant?.name && <Breadcrumbs pageTitle={`${merchant?.name} history`} />}
    </Container>
    <Container fluid className={styles.tableContainer}>
      <Table
        id="merchant_history"
        data={getHistory}
        headers={historyHeaders}
        maxHeight={450}
      />
    </Container>
    <TimeZoneMessage timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone} />
  </div >;
};

export default MerchantHistory;
