import styles from "./Settings.module.scss";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "reducers/Hooks";
import { Grid, InfoContainer } from "common";
import BusinessInformationCard from "./accountInformation/BusinessInformationCard";
import UserAccountCard from "./accountInformation/UserAccountCard";
import MainContactCard from "./accountInformation/MainContactCard";
import { IAddress } from "common/interfaces";
import { getSubdomainIfAny } from "utils/helpers";
import SupportInformationCard from "./accountInformation/SupportInformationCard";
import WhiteLabelHandler from "actions/WhiteLabelHandler";
import Observer, { EVENTS } from "classes/Observer";
import SystemHandler from "actions/SystemHandler";
import _ from "lodash";
import { DollarCircle } from "iconsax-react";

const WhiteLabelAccountInformation: React.FC = () => {
  const whitelabel = useAppSelector(state => state.whitelabel);
  const slug = getSubdomainIfAny();
  const support = useAppSelector(state => state.app.support);
  const observer = Observer.useObserver(EVENTS.WHITELABEL_UPDATED);
  const [lenderGroups, setLenderGroups] = useState(null);

  useEffect(() => {
    if (slug && !whitelabel) {
      WhiteLabelHandler.get(slug, false);
      SystemHandler.getSupportInfo();
    }
  }, [slug]);

  useEffect(() => {
    WhiteLabelHandler.get(slug, false);
    SystemHandler.getSupportInfo();
  }, [observer]);

  useEffect(() => {
    WhiteLabelHandler.getLenderGroups()
      .then(response => {
        setLenderGroups(response);
      });
  }, []);

  return <div className={styles.contentContainer}>
    {whitelabel && <Grid horizontal>
      <Grid>
        <Grid item>
          <BusinessInformationCard
            id="whitelabel_account_information"
            businessName={whitelabel?.name}
            mainAddress={{
              address1: whitelabel?.address1,
              address2: whitelabel?.address2 || "",
              city: whitelabel?.city,
              state: whitelabel?.state,
              postal_code: whitelabel?.postal_code
            } as IAddress}
            type="WHITELABEL"
          />
        </Grid>
        <Grid item>
          <UserAccountCard />
        </Grid>
      </Grid>
      <Grid>
        <Grid item>
          <MainContactCard
            cardTitle="Business main contact"
            firstName={whitelabel?.main_contact_first_name}
            lastName={whitelabel?.main_contact_last_name}
            email={whitelabel?.main_contact_email}
            phone={whitelabel?.main_contact_phone}
          />
        </Grid>
        {lenderGroups && lenderGroups.length > 0 && <Grid item>
          <InfoContainer
            id="lender_group_card"
            title="Lenders"
            rows={[
              {
                label: "Lender groups", value: _.sortBy(lenderGroups, ["name"]).map((lender: any) => { return `${lender.name}${lender.default ? "[chip=default]" : ""}` })
              }
            ]}
            icon={DollarCircle}
          />
        </Grid>}
        {support?.merchant && <Grid item>
          <SupportInformationCard
            type="MERCHANT_HELP"
            contactName={support?.merchant?.name}
            email={support?.merchant?.email}
            phone={support?.merchant?.phone}
          />
        </Grid>}
        {support?.borrower && <Grid item>
          <SupportInformationCard
            type="BORROWER_HELP"
            contactName={support?.borrower?.name}
            email={support?.borrower?.email}
            phone={support?.borrower?.phone}
          />
        </Grid>}
      </Grid>
    </Grid>}
  </div>;
};

export default WhiteLabelAccountInformation;
