import {
  IAddress, IAddressInfo, IConfirmationButton, IDataPoint, IDTCFinancialInfo, IDTMAddress, IDTMApplication, IDTMApplicationLegalAddress, IDTMFinancialInfo, IFeedback, IImageDimensions,
  IMerchantLocation, IMiniFeedback, IResource, ISmartyAddress, IStepper, ISupport, IUser, IUSPSAddress
} from "common/interfaces";
import _, { forOwn } from "lodash";
import store from "reducers/Store";
import borrowerActions from "reducers/BorrowerReducer";
import popUpActions from "reducers/PopUpReducer";
import animationActions from "reducers/AnimationReducer";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from 'uuid';
import { ReactElement } from "react";
import LocalStorage from "classes/LocalStorage";
import moment from "moment";
import { ENABLE_FINANCING_API_INSTANCE, ENABLE_FINANCING_API_INSTANCE_NO_TOKEN } from "actions/ActionConstants";
import EnableCache from "classes/EnableCache";
import SystemHandler from "actions/SystemHandler";
import { Chip } from "@mui/material";
import Analytics, { ITracking } from "classes/Analytics";
import BorrowerHandler from "actions/BorrowerHandler";
import { convertDateFormatDashToSlash, formatAPIPhone } from "./formatters";
import { POPUPS } from "./constants";
import dtmApplicationActions from "reducers/DTMApplicationReducer";
import { EmptySpace } from "common/form";
import appActions from "reducers/AppReducer";

/**
 * @description Extract subdomain from the URL.
 * @returns {string} Subdomain or null.
 */
export const getSubdomainIfAny = (): string => {
  let subdomain = "";

  const host = window.location.host;
  const parts = host.split(".");

  // If we get more than 3 parts, then we have a subdomain
  // INFO: This could be 4, if you have a co.uk TLD or something like that.
  if (parts.length >= 3) {
    subdomain = parts[0];
  }

  return subdomain;
};

/** @description Convert a name/id object to a label/value
 * @param {any} data Api list response.
 * @return {Array<any>} An array of label/value.
 */
export function convertFromNameIdToLabelValue(data: any): Array<any> {
  return data.results.map((item, index) => {
    return { ...item, label: item.name || item.label, value: item.id.toString() };
  });
}

/** @description Convert object to a label/value
 * @param {any} data Api list response.
 * @return {Array<any>} An array of label/value.
 */
export function convertFromObjectToLabelValue(data: any): Array<any> {
  return Object.entries(data).map(([key, value]) => ({
    label: value,
    value: key
  }));
}

/** @description Display feedback pop up.
 * @param {IFeedback} details Error details.
 * @param {boolean} forceFeedback Force displaying feedback instead of mini feedback.
 */
export function displayFeedback(details: IFeedback | null, forceFeedback: boolean = false) {
  scrollTop();
  if (!forceFeedback && (store.getState().popUp.showPopup || store.getState().popUp.showInnerPopup)) {
    const negativeFeedbackTypes = ["UNPLUGGED", "RESTRICTED", "UFO", "GEARS", "BLANK"];
    displayMiniFeedback(details.body || "Oops! Something went wrong!", negativeFeedbackTypes.includes(details.type));
  } else {
    store.dispatch(popUpActions.displayFeedbackPopup(details));
  }
}

/** @description Simple encryption.
 * @param {any} value Object to encrypt.
 * @returns Encryption text.
 */
export function encrypt(value: any): string {
  const key = uuidv4();
  const cypher = CryptoJS.AES.encrypt(typeof value === typeof "" ? value : JSON.stringify(value), key);
  return `${cypher.toString()}..${key}`;
}

/** @description Decrypt text to an object string.
 * @param {string} value Text to decrypt.
 * @returns Object string.
 */
export function decrypt(value: string): string {
  const parts = value.split('..');
  const data = CryptoJS.AES.decrypt(parts[0], parts[1]).toString(CryptoJS.enc.Utf8);
  return data;
}

/** @description Returns true if the pop up is opened.
 * @param {any} state Redux state.
 * @param {string} popupName Pop up name that matches redux store.
 * @returns True if opened.
 */
export function isPopupOpened(state: any, popupName: string): boolean {
  const { inner, fullscreen } = state.popUp;
  let isOpen = false;
  if (inner.names.includes(popupName)) {
    // is inner pop up
    isOpen = inner.isOpen[inner.names.indexOf(popupName)];
  } else if (fullscreen.names.includes(popupName)) {
    // is full screen popup
    isOpen = fullscreen.isOpen[fullscreen.names.indexOf(popupName)];
  }
  return isOpen;
}

export const preventTextSelection = (event: any): void => {
  if (event.detail > 1) {
    event.preventDefault();
  }
}

/** 
 * @description This function takes an array of React Elements of type IStepper as an argument and returns the index of the current step in the array.
 * @param {Array<ReactElement<IStepper>>} steps An array of React Elements of type IStepper.
 * @param {number} partsCount basic parts count of the flow - Should be 3 for borrower experience or 2 for all other flows. If path structure changes, this might change too.
 * @returns An integer representing the index of the current step in the steps array.
 * @usage This function is used to determine which step the user is currently on. It looks at the pathname of the current URL and compares it to the path property of each element in the steps array. If the pathname matches one of the path properties, the index of that element is returned. If no paths match, the index of the first element is returned 
 * */
export const getFlowStep = (steps: Array<ReactElement<IStepper>>, partsCount: number): number => {
  const path = window.location.pathname;
  const parts = path.split('/');

  if (parts.length > partsCount) {
    const currentStepIndex = _.findIndex(steps, step => { return step.props.path.toLowerCase() === parts[partsCount].toLowerCase() });
    if (currentStepIndex >= 0) {
      return currentStepIndex;
    }
  }

  // url points to the first step
  return 0;
}

/** 
 * @description Tries to find a valid page before the Not Found hit.
 * @returns A url.
 * */
export const getVisitedPageBeforeNotFound = (): string => {
  const pages = [...store.getState().app.lastVisitedPages];
  let count = 0;
  const index = pages
    .reverse()
    .findIndex((page) => page !== "/notFound" && ++count === 2);
  return index !== -1 ? pages[pages.length - index - 1] : null;
}

export const csvEscape = function (text: string): string {
  if (!text) {
    return text;
  }
  const newText = text
    .replace(/,/g, "")
    .replace(/#/g, "")
    .trim();
  return newText;
};

/**
 * @summary Merges 2 objects, if both objects has the same property, obj2 will override obj1 property
 *
 * @param {object} obj1 - The base object that will be modified
 * @param {object} obj2 - The second object that will add or overwrite properties
 */
export function mergeRecursive(obj1: object, obj2: object): object {
  // creating a new copy of an object in case obj1 is read-only
  const result = { ...obj1 };

  for (const p in obj2) {
    if (obj2.hasOwnProperty(p)) {
      try {
        // If both obj1 and obj2 properties are objects, recursively merge
        if (obj2[p]?.constructor === Object) {
          result[p] = mergeRecursive(result[p] || {}, obj2[p]);
        } else {
          result[p] = obj2[p]; // Otherwise, just copy the value from obj2
        }
      } catch (e) {
        // If any error occurs, assign the value from obj2 to the result
        result[p] = obj2[p];
      }
    }
  }

  return result; // Return the new merged object
}

export function displayApplicationLockedFeedbackPopup(handleNewApplication: () => void, handleNeverMind: () => void) {
  displayFeedback({
    title: `Unfortunately, you can't go back.`,
    body: "Your application has already been submitted. You can submit another application or continue with your current one.",
    type: "RESTRICTED",
    buttons: [
      {
        id: "offers_new_application",
        label: "New application",
        style: { width: "170px" },
        action: () => {
          LocalStorage.remove("vuid");
          LocalStorage.remove("application_id");
          LocalStorage.remove("application_locked");
          LocalStorage.remove("dob");
          store.dispatch(popUpActions.closePopup());
          Analytics.track({ experience: "borrower", screen: "application_offers_redirect", object: "continue_button", action: "clicked" } as ITracking, null);
          handleNewApplication();
        }
      },
      {
        id: "offers_never_mind",
        label: "Never mind",
        action: () => {
          Analytics.track({ experience: "borrower", screen: "application_offers_redirect", object: "back_arrow", action: "clicked" } as ITracking, null);
          handleNeverMind();
          store.dispatch(popUpActions.closePopup());
        }
      },
    ]
  } as IFeedback);
}

/** 
 * @description Checks if a date is in the future
 * @param {string} MM month in MM format
 * @param {string} DD month in DD format
 * @param {string} YYYY month in YYYY format
 * */
export const isFutureDate = (MM: string, DD: string, YYYY: string) => {
  const currentDate = new Date();
  const inputDate = new Date(YYYY + "-" + MM + "-" + DD);

  return inputDate.getTime() > currentDate.getTime();
};

export const moveObjectToFront = (arr: [object], i: number) => {
  const obj = arr.splice(i, 1)[0];
  arr.unshift(obj);
  return arr;
};

/** 
 * @description Generate Google reCaptcha.
 * @returns Promise
 * */
export const generateCaptcha = async (): Promise<void> => {
  const recaptcha_token = EnableCache.get<string>("recaptcha");
  if (!recaptcha_token) {
    const token = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'login' })
    EnableCache.save("recaptcha", token, 2, () => { return generateCaptcha(); });
    ENABLE_FINANCING_API_INSTANCE.defaults.headers["enable-captcha"] = token;
    ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.defaults.headers["enable-captcha"] = token;
  } else {
    ENABLE_FINANCING_API_INSTANCE.defaults.headers["enable-captcha"] = recaptcha_token;
    ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.defaults.headers["enable-captcha"] = recaptcha_token;
  }
  return Promise.resolve();
}

/** 
 * @description Display dashboard loading bar.
 * @param {string} text Message that is displayed inside the loading bar.
 * */
export const displayLoadingBar = (text?: string): void => {
  store.dispatch(animationActions.fadeInLoadingBar(text));
}

/** 
 * @description Hide dashboard loading bar.
 * */
export const hideLoadingBar = (): void => {
  store.dispatch(animationActions.fadeOutLoadingBar());
}

/**
 * @summary Returns true, if objects are equal
 *
 * @param {object} obj1 - object 1
 * @param {object} obj2 - object 2
 */
export function objectsAreEqual(obj1: object, obj2: object) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

/** 
 * @description Display fullscreen loading animation.
 * @param {string} text Message that is displayed inside the loading animation.
 * */
export const displayFullscreenLoading = (text?: string): void => {
  store.dispatch(animationActions.activateLoading(text));
}

/** 
 * @description Hide fullscreen loading bar.
 * */
export const hideFullscreenLoading = (): void => {
  store.dispatch(animationActions.deactivateLoading());
}

export const removeQueryStrings = (url: string, paramNames: Array<string>): string => {
  paramNames.forEach(param => {
    url = removeQueryString(url, param);
  });
  return url;
}

export const removeQueryString = (url: string, paramName: string): string => {
  if (!url) {
    return url;
  }

  if (url.startsWith('/')) {
    url = url.substring(1);
  }
  const fullUrl = `${process.env.REACT_APP_DB_URL}${url}`;
  const urlObj = new URL(fullUrl);
  const searchParams = urlObj.searchParams;

  // Delete the query parameter if it exists
  if (searchParams.has(paramName)) {
    searchParams.delete(paramName);
  }

  // Reconstruct the URL without the specific query parameter
  const newUrl = urlObj.pathname + '?' + searchParams.toString() + urlObj.hash;
  return newUrl;
}

export const getQueryString = (url: string): any => {
  if (!url) {
    return {};
  }
  const fullUrl = `${process.env.REACT_APP_DB_URL}${url.substring(1)}`;
  const urlObj = new URL(fullUrl);
  const searchParams = urlObj.searchParams;
  const params: { [key: string]: string } = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
}

export const isNotNullNorUndefined = (obj: any): boolean => {
  return obj !== undefined && obj !== null;
}

export const isUndefinedNullOrZero = (obj: any): boolean => {
  return obj === undefined || obj === null || obj === 0;
}

export const isNullOrUndefinedOrEmpty = (obj: string): boolean => {
  return obj === undefined || obj === null || (typeof (obj) === "string" && obj?.trim() === "");
}

export const getStartDate = (dropdownValue: string): string => {
  let date_from = null;

  switch (dropdownValue) {
    case "7days":
      date_from = moment().add(-7, 'days');
      break;
    case "30days":
      date_from = moment().add(-30, 'days');
      break;
    case "365days":
      date_from = moment().add(-365, 'days');
      break;
    case "mtd":
      date_from = moment().startOf('month');
      break;
    case "ytd":
      date_from = moment().startOf('year');
      break;
    default:
      break;
  }

  return date_from?.format('YYYY-MM-DD');
}

export const getStatusIdGlobal = (statuses, name: string) => {
  return _.find(statuses, item => { return item.name === name })?.id;
}

export const handleNull = (text: string | number | null, replaceWith?: string): string => {
  const noSpaces = text?.toString().replace(/ /g, "");
  return noSpaces ? text?.toString() : replaceWith;
}

export const parseMainContactInformation = (settings: any) => {
  return {
    main_contact_first_name: settings.main_contact_first_name || "",
    main_contact_last_name: settings.main_contact_last_name || "",
    main_contact_phone: settings.main_contact_phone ? formatAPIPhone(settings.main_contact_phone) : "",
    main_contact_email: settings.main_contact_email || ""
  };
};

export const parseChartData = (dataSet: any, series1: string, series2: string): Array<IDataPoint> => {
  if (!dataSet) {
    return [];
  }
  let data: Array<IDataPoint> = [];
  _.forOwn(dataSet, (value: any, key: string) => {
    data.push({
      name: key,
      series1: value[series1],
      series2: value[series2]
    });
  });
  return data;
}

/** 
 * @description Return true if the screen is small (potential mobile device).
 * @return {boolean} True if screen is small.
 */
export const isSmallScreen = (): boolean => {
  return window.innerWidth <= 600;
};

/** 
 * @description Scroll page to the top.
 */
export const scrollTop = (): void => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

/** 
 * @description Display mini feedback popup.
 * @param {string} message Message that is displayed inside the popup.
 * @param {boolean} isError If feeback message is an error.
 * @param {number} closeTimer How long should the popup be displayed before it auto closes. In miliseconds.
 * */
export const displayMiniFeedback = (message: string, isError?: boolean, closeTimer?: number): void => {
  store.dispatch(animationActions.displayMiniFeedback({ message, isError: isError || false, closeTimer } as IMiniFeedback));
}

/** 
 * @description Get status id.
 * @param {string} status Status label.
 * @returns Id.
 * */
export const getStatus = async (status: "Active" | "Pending" | "Deactivated" | "Declined"): Promise<number> => {
  const statuses = await SystemHandler.getCustomerStatus();
  return Promise.resolve(_.find(statuses, item => { return item.name === status })?.id);
}

/** 
 * @description Get all statuses.
 * @returns Statuses.
 * */
export const getAllStatuses = async (): Promise<Array<any>> => {
  const statuses = await SystemHandler.getCustomerStatus();
  return Promise.resolve(statuses);
}

/** 
 * @description Get error description for tracking.
 * @param {any} error Error object.
 * @returns Error message.
 * */
export const getAPIErrorForTracking = (error: any): string => {
  return decypherApiErrorResponse(error, "");
}

/** 
 * @description Try to decypher API Bad Request response.
 * @param {any} error Error object.
 * @param {string} defaultErrorMessage Default error message.
 * @default defaultErrorMessage Something went wrong. Please try again.
 * @returns Friendly message.
 * */
export const decypherApiErrorResponse = (error: any, defaultErrorMessage?: string): string => {
  let body = defaultErrorMessage || "Something went wrong. Please try again.";
  if (error?.response?.data?.detail) {
    body = error?.response?.data?.detail;
  } else if (error?.response?.data?.non_field_errors?.length > 0) {
    body = error.response.data.non_field_errors.join(', ');
  } else if (error?.response?.data) {
    let newBody = "";
    forOwn(error.response.data, (value, key) => {
      let text = "";
      if (typeof value === typeof "") {
        text += value;
      } else if (typeof value === typeof []) {
        text += (value as Array<string>).join(', ');
      }
      newBody += `${text}\n`;
    });
    if (newBody) {
      body = newBody;
    }
  }
  return body;
}

export const generateCsvFile = (template: Array<Array<string>>): void => {
  // Create the CSV content
  const csvContent = "data:text/csv;charset=utf-8," + template.map(row => row.join(",")).join("\n");

  // Create a temporary link element
  const link = document.createElement("a");
  link.setAttribute("href", encodeURI(csvContent));
  link.setAttribute("download", "template.csv");

  // Trigger the download
  link.click();
}

export const createMerchantSlug = (merchant: any): string => {
  return sanitizeString(merchant?.name);
}

export const sanitizeString = (input: string): string => {
  return input.replace(/\s+/g, "_").replace(/[^a-zA-Z_0-9]/g, "").replace(/_+/g, "_").toLowerCase();
};

export const fillInitialValues = (initialValues: any, values: any): any => {
  let newObj: any = {};
  _.forOwn(initialValues, (iv, ivKey) => {
    if (["phone_number", "main_contact_phone"].includes(ivKey)) {
      newObj[ivKey] = formatAPIPhone(values[ivKey]);
    } else {
      newObj[ivKey] = values[ivKey] || "";
    }
  });
  return newObj;
}

export const getDomainFromHostname = (): string => {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");

  // Check if the hostname contains a subdomain
  if (parts.length > 2) {
    // Remove the subdomain and return the remaining parts
    return `.${parts.slice(1).join(".")}`;
  }

  // No subdomain found, return the full hostname
  return hostname;
}

export const concatenateAddress = (address: IAddress | IMerchantLocation) => {
  return (address.address1 ? address.address1 + " " : "") + (address.address2 ? address.address2 + " - " : "") + (address.city ? address.city + ", " : "") + (address.state ? address.state.toUpperCase() + " - " : "") + (address.postal_code ?? "");
}

export const concatenateSmartyAddress = (address: ISmartyAddress) => {
  return (address.street_line ? address.street_line + " " : "") + (address.secondary ? address.secondary + " - " : "") + (address.city ? address.city + ", " : "") + (address.state ? address.state.toUpperCase() + " - " : "") + (address.zipcode ?? "");
}

export const concatenateSmartyAddressLine1and2 = (address: ISmartyAddress) => {
  return (address.street_line ? address.street_line : "") + (address.secondary ? " " + address.secondary : "");
}

export const IAddressInfo2IUSPSAddress = (address: IAddressInfo): IUSPSAddress => {
  return {
    Address1: address.address1,
    Address2: address.address2,
    City: address.city,
    State: address.state,
    Zip5: address.postal_code,
  }
}

export const IDTMApplicationLegalAddress2IUSPSAddress = (address: IDTMApplication | IDTMApplicationLegalAddress): IUSPSAddress => {
  return {
    Address1: address.legal_address1,
    Address2: address.legal_address2,
    City: address.legal_city,
    State: address.legal_state,
    Zip5: address.legal_zip_code,
  }
}

export const IUSPSAddress2IDTMApplicationLegalAddress = (address: IUSPSAddress): IDTMApplicationLegalAddress => {
  return {
    legal_address1: address.Address1,
    legal_address2: address.Address2,
    legal_city: address.City,
    legal_state: address.State,
    legal_zip_code: address.Zip5,
  }
}

export const handleInnerMenusBackButton = (possiblePaths: Array<string>, setViewApplication: (view: string) => void) => {
  // This handles the browser back button
  const handlePopstate = () => {
    const currentPath = window.location.pathname;
    for (let index = 0; index < possiblePaths.length; index++) {
      const element = possiblePaths[index];
      if (currentPath.endsWith(element)) {
        setViewApplication(element);
      }
    }
  };
  window.addEventListener('popstate', handlePopstate);
  return () => {
    window.removeEventListener('popstate', handlePopstate);
  };
}

export const getPreApprovedPreQualifiedText = (pre_approved: boolean, pre_qualified: boolean): string => {
  // 3 conditions instead of 2 just for clarity
  let prequalifiedPreapprovedText = "";
  if (pre_qualified && pre_approved) {
    prequalifiedPreapprovedText = "Pre-Approved";
  } else if (pre_approved) {
    prequalifiedPreapprovedText = "Pre-Approved";
  } else if (pre_qualified) {
    prequalifiedPreapprovedText = "Pre-Qualified";
  }
  return prequalifiedPreapprovedText;
}

export const getPreApprovedPreQualifiedTextSentence = (pre_approved: boolean, pre_qualified: boolean): string => {
  // 3 conditions instead of 2 just for clarity
  let prequalifiedPreapprovedText = "";
  if (pre_qualified && pre_approved) {
    prequalifiedPreapprovedText = "pre-approved";
  } else if (pre_approved) {
    prequalifiedPreapprovedText = "pre-approved";
  } else if (pre_qualified) {
    prequalifiedPreapprovedText = "pre-qualified";
  }
  return prequalifiedPreapprovedText;
}

export const renderChipIfPresent = (data: any): any => {
  if (!data) {
    return null;
  }

  if (typeof data === typeof "" && data.toString().includes("[chip=")) {
    const contentRegex = /\[(.*?)\]/;
    const chipRegex = /chip=(\w+)/;
    const str = data.toString();

    const contentMatch = str.match(contentRegex);
    const chipMatch = str.match(chipRegex);
    const chip = chipMatch ? chipMatch[1] : null;
    const content = contentMatch ? (
      <>
        {str.split(contentRegex)[0]}
        <Chip
          size="small"
          label={chip}
          style={{
            backgroundColor: "white",
            border: "1px solid var(--primaryColor)",
            color: "var(--primaryTextColor)",
            fontSize: "1.2rem",
            height: 25,
            padding: 3,
            marginLeft: 5,
            marginTop: -3
          }} />
        {str.split(contentRegex)[2]}
      </>
    ) : null;

    return <>{content}</>;
  }

  return data;
}

// not used at the moment
export const getNameInitials = (businessName: string): string => {
  const words = businessName?.split(" ");
  let initials = "";
  for (let i = 0; i < words?.length; i++) {
    initials += words[i]?.trim()[0].toUpperCase() + ".";
    if (i < words.length - 1) {
      initials += " ";
    }
  }
  return initials;
}

// currently not used anywhere - 07/24/2024
export const getLogoHeightBasedOnDimensions = (logoDimensions: IImageDimensions): number => {
  const merchantLogoRatio = logoDimensions ? (logoDimensions.width / logoDimensions.height) : 2;
  const maxHeight = isSmallScreen() ? 50 : 50;
  const minHeight = isSmallScreen() ? 40 : 40;
  let height = maxHeight;
  const ratioTresholdLong = 2;// example image w * h: 200 x 100
  const ratioTresholdShort = 1;// square, example image w * h: 100 x 100
  if (merchantLogoRatio >= ratioTresholdLong) {
    height = minHeight;
  } else if (merchantLogoRatio <= ratioTresholdShort) {
    height = maxHeight;
  } else {
    // linear interpolation between tresholds
    height = minHeight + (ratioTresholdLong - merchantLogoRatio) * (ratioTresholdLong - ratioTresholdShort) * (maxHeight - minHeight);
  }

  return height;
}

export function getTimeZoneOffset() {
  try {
    const offset = moment().utcOffset();
    const offsetInHours = Math.floor(offset / 60);
    const offsetInMinutes = offset % 60;
    const formattedOffset = `${offsetInHours < 0 ? '-' : '+'}${Math.abs(offsetInHours).toString().padStart(2, '0')}${Math.abs(offsetInMinutes).toString().padStart(2, '0')}`;
    return formattedOffset;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function displayDuplicatedApplicationFeedbackPopup(additionalDetails: string, input: string, displayViewOffers: boolean) {
  let buttons: Array<any> = [
    {
      id: "close_popup",
      label: "Back",
      action: () => {
        store.dispatch(popUpActions.closePopup());
      },
      secondary: true
    }
  ];
  if (displayViewOffers) {
    buttons.push({
      id: "view_offers",
      label: "View offers",
      action: () => {
        window.location.href = `/verifyDOBandSSN`;
        store.dispatch(popUpActions.closePopup());
      }
    });
  }
  displayFeedback({
    title: `Similar application already exists!`,
    body: `Application with this ${input} has already been submitted. ${additionalDetails}.`,
    type: "GEARS",
    buttons
  } as IFeedback);
}

export function findDifferentKeysInObjects(obj1: object, obj2: object): Array<any> {
  let diff = Object.keys(obj2).reduce((diff, key) => {
    if (obj1[key] === obj2[key]) return diff
    return {
      ...diff,
      [key]: obj2[key]
    }
  }, {})
  // return diff;
  return Object.keys(diff) || [];
}

/**
 * @description Removes duplicates from array of objects
 */
export function removeDuplicates(arr: Array<any>): Array<any> {
  const uniqueArray = arr.filter((value, index) => {
    const _value = JSON.stringify(value);
    return index === arr.findIndex(arr => {
      return JSON.stringify(arr) === _value;
    });
  });
  return uniqueArray;
}

/**
* @description Returns true if application has DOB already filled in, so you know if DOB verification is required.
* @param {number} application_id Application id.
* @param {string} vuid vuid sent on the invitation email.
*/
export async function applicationHasDOB(application_id: number, vuid: string): Promise<boolean> {
  try {
    const response = await BorrowerHandler.getApplicationDataWithoutDOB(application_id, vuid);

    if (response.date_of_birth === null) {
      return false;
    }

  } catch (error) {
    if (error.data.date_of_birth === "Date of birth does not match") {
      return true;
    }
  }

  return false;
}

export function prepareNewApplication() {
  // these 3 LocalStorage fields should already be removed here - remove it just in case
  LocalStorage.remove("vuid");
  LocalStorage.remove("application_id");
  LocalStorage.remove("application_locked");
  LocalStorage.remove("no_offers");
  LocalStorage.remove("dob");

  store.dispatch(borrowerActions.clearApplication());
};

export function isForMerchantGlobal(user: IUser, merchantSlug?: string) {
  return (user?.user_type === "MERCHANT") || (user?.user_type === "WHITELABEL" && merchantSlug) || (user?.user_type === "PARTNER" && merchantSlug);
}

export function isForPartnerGlobal(user: IUser, partnerSlug?: string, merchantSlug?: string) {
  return (user?.user_type === "PARTNER" && !merchantSlug) || (user?.user_type === "WHITELABEL" && partnerSlug);
}

/**
 * Removes the `lender_group` property from a given object, returning a new object without this property.
 * 
 * @param {Object} obj The original object from which the `lender_group` property will be removed.
 * @returns {Object} A new object that is a copy of the original but without the `lender_group` property.
 */
export function removeLenderGroup(obj: any) {
  const { lender_group, ...rest } = obj;
  return rest;
}

/**
 * Type guard function to check if a given object is of type ISupport.
 * 
 * @param {ISupport | IResource[]} obj The object to check. Can be either of type ISupport or an array of IResource.
 * @returns {boolean} True if the object has a property 'phone' indicating it's of type ISupport; otherwise, false.
 */
export function isSupport(obj: ISupport | IResource[]): obj is ISupport {
  return (obj as ISupport).phone !== undefined;
}

/**
 * Attempts to parse a string to a number, allowing for both integer and float parsing.
 * 
 * @param {string} value The string value to parse into a number.
 * @param {boolean} [isFloat=false] Flag indicating whether to parse the value as a float (true) or integer (false). Defaults to false.
 * @returns {number} The parsed number. Returns 0 if parsing fails or if the input is not a valid number.
 */
export function tryParseNumber(value: string, isFloat: boolean = false) {
  let number = 0;
  try {
    number = isFloat ? parseFloat(value?.toString() || "0") : parseInt(value?.toString() || "0");
  } catch {
    number = 0;
  }
  return number;
}

/**
 * Extracts and returns the role from a given group name by removing specific prefixes.
 * 
 * @param {string} group The group name from which the role is extracted. Prefixes like 'White Label ', 'Merchant ', and 'Partner ' are removed.
 * @returns {string} The role name without the specified prefixes. If the input is falsy or after removal nothing remains, returns an empty string.
 */
export const getRole = (group: string): string => {
  return group?.replace('White Label ', '').replace('Merchant ', '').replace('Partner ', '') || '';
}

/**
 * Removes the 'http://' or 'https://' protocol from a given URL string.
 * 
 * @param {string} url The URL from which the protocol should be removed.
 * @returns {string} The URL without the 'http://' or 'https://' protocol. If the input is falsy, returns an empty string.
 */
export const removeProtocol = (url: string): string => {
  if (!url) {
    return "";
  }

  try {
    return new URL(url).hostname;
  } catch {
    // Probably not an actual domain, so it fails the conversion
  }
  return url;
}

/**
 * Triggers a confirmation popup with customizable actions for yes and no responses.
 * @param {any} body The content to display in the confirmation popup.
 * @param {IConfirmationButton} yesButton The callback function to execute when the user confirms (yes option) and the button text.
 * @param {IConfirmationButton} noButton The callback function to execute when the user declines (no option) and the button text.
 * @param {boolean} fullScreen Optional - Present pop up in full screen mode.
 * @param {number} maxWidth Optional - Max width for the pop up.
 * @returns {void}
 */
export const askForConfirmation = (body: any, yesButton: IConfirmationButton, noButton: IConfirmationButton, fullScreen?: boolean, maxWidth?: number, title?: string, imageSrc?: string): void => {
  store.dispatch(popUpActions.openPopup({
    name: fullScreen ? POPUPS.CONFIRMATION_FULL : POPUPS.CONFIRMATION_INNER,
    message: {
      body,
      yesButton,
      noButton,
      maxWidth,
      title,
      imageSrc,
    }
  }));
}

export const closePopup = () => {
  store.dispatch(popUpActions.closePopup());
}

/**
 * Checks if the given amount is valid and greater than 0.
 * 
 * @param {any} amount - The amount to be validated.
 * @returns {boolean} - Returns `true` if the amount is not null, not undefined, not an empty string, and greater than 0; otherwise, returns `false`.
 */
export const isAmountValid = (amount: any): boolean => {
  if (amount == null || amount == undefined || amount?.toString().trim() === "") {
    return false;
  }
  return parseFloat(amount?.toString() || "0") > 0;
}

/**
 * Checks if the given string is present (not null, not undefined, and not an empty string).
 * 
 * @param {any} str - The string to be checked.
 * @returns {boolean} - Returns `true` if the string is not null, not undefined, and not an empty string; otherwise, returns `false`.
 */
export const isStringPresent = (str: any): boolean => {
  if (str == null || str == undefined || str?.toString().trim() === "") {
    return false;
  }
  return true;
}

export const simplifyUrl = (url: string) => {
  // Match the protocol, the first part of the domain, and the top-level domain
  const match = url.match(/(http:\/\/)([^-.]+)([^:\/]*\.[^:\/]*)(:\d+)?(\/|$)/);

  if (match) {
    // Reconstruct the URL with the first part of the domain and the top-level domain
    return `${match[1]}${match[2]}...${match[3]}`;
  } else {
    // Return the original URL if it doesn't match the expected format
    return url;
  }
}

export function objectToArray(obj, changeLabelAndValue = false) {
  if (changeLabelAndValue) {
    return Object.entries(obj).map(([value, label]) => ({ label, value }));
  }
  return Object.entries(obj).map(([label, value]) => ({ label, value }));
}

export const isAddressTheSame = (address1: IDTMAddress, address2: IDTMAddress): boolean => {
  // If address2 is blank comparison is not necessary
  if (isNullOrUndefinedOrEmpty(address2.address1) &&
    isNullOrUndefinedOrEmpty(address2.city) &&
    isNullOrUndefinedOrEmpty(address2.state) &&
    isNullOrUndefinedOrEmpty(address2.zip_code)) {
    return true;
  }

  // Compares values of the two addresses
  return (
    address1.address1 === address2.address1 &&
    address1.city === address2.city &&
    address1.state === address2.state &&
    address1.zip_code === address2.zip_code
  );
}

export const isNullOrUndefined = (obj: any): boolean => {
  return obj === null || obj === undefined;
}

export const numberToOrdinalText = (num: number): string => {
  const ordinals = ["Zeroth", "First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh", "Eighth", "Ninth", "Tenth"];

  if (num < 0 || num >= ordinals.length) {
    throw Error("Number out of range");
  }

  return ordinals[num];
}

export const areObjectsEqual = (obj1: any, obj2: any): boolean => {
  // Get all keys from the first object
  const keys = Object.keys(obj1);

  // Check if every key-value pair matches in the second object
  return keys.every(key => obj1[key] === obj2[key]);
}

export function convertStringNullsToNull(obj: any) {
  Object.keys(obj).forEach(key => {
    if (obj[key] === "null") {
      obj[key] = null;
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      convertStringNullsToNull(obj[key]);
    }
  });
}

/**
 * @description Recursively removes fields from an object (or array) where the value is a string consisting only of one or more asterisks (*).
 * @param {any} obj Object (or array) to remove the fields from
 * @param {string} [parent] Optional parameter used to keep track of parent object, needed for array adjustments
 */
export function removeFieldsWithOnlyAsterisks(obj: any, parent?: any): void {
  if (Array.isArray(obj)) {
    // Iterate over array and remove items recursively
    for (let i = 0; i < obj.length; i++) {
      if (typeof obj[i] === 'string' && /^[\*]+$/.test(obj[i])) {
        // Removing item if it matches and updating loop counter
        obj.splice(i, 1);
        i--;
      } else if (typeof obj[i] === 'object' && obj[i] !== null) {
        removeFieldsWithOnlyAsterisks(obj[i], obj);
      }
    }
  } else if (obj && typeof obj === 'object') {
    for (const key in obj) {
      if (typeof obj[key] === 'string' && /^[\*]+$/.test(obj[key])) {
        // Delete property if it is a string of only asterisks
        delete obj[key];
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        // Recursive call to navigate deeper into the object
        removeFieldsWithOnlyAsterisks(obj[key], obj);
        // Check if the object or array is empty after deletion and consider removing it
        if (Object.keys(obj[key]).length === 0 && Array.isArray(obj[key]) && obj[key].length === 0) {
          delete obj[key];
        }
      }
    }
  }

  // If current array becomes empty as a result of removals, delete it from its parent
  if (Array.isArray(parent) && Array.isArray(obj) && obj.length === 0) {
    const index = parent.indexOf(obj);
    if (index > -1) {
      parent.splice(index, 1);
    }
  }
}

/**
 * @description Returns true if input string consists only of one or more asterisks with no other characters
 */
export function containsOnlyAsterisks(input: string): boolean {
  return /^[\*]+$/.test(input);
}

export const loadDtmDocs = (application: IDTMApplication) => {
  const dtmDocKeys = ['doc_voided_check', 'doc_refund_policy', 'doc_incorporation_articles', 'doc_ein_letter', 'doc_monthly_statement_1', 'doc_monthly_statement_2', 'doc_monthly_statement_3', 'doc_year_profit_and_loss', 'doc_year_balance_sheet'];
  dtmDocKeys.forEach(doc => {
    store.dispatch(dtmApplicationActions.addDoc({ key: doc, doc: application[doc] ? `${doc.replace("_doc", "")}.png` : null }));
  });
}

export const isOnlyAsterisks = (input: string): boolean => {
  return /^[\*]+$/.test(input);
}

export const getMerchantPrograms = (merchant: any) => {
  if (merchant?.programs.includes("DTC") && merchant?.programs.includes("DTM")) {
    return "DTC and DTM business model";
  } else if (merchant?.programs.includes("DTC")) {
    return "DTC business model";
  } else if (merchant?.programs.includes("DTM")) {
    return "DTM business model";
  }
  return "";
}

/**
 * @description Splits array of objects to array with arrays of 2 objects
 * example: [a, b, c, d, e] -> [[a, b], [c, d], [e]]
 * also takes into account the possible 3rd element "or"
 * @param {boolean} addEmptySpace if true, adds an <EmptySpace> element at the end if the number of elements is odd.
 */
export const splitFormInputFieldList = (arr: Array<any>, addEmptySpace?: boolean) => {
  let result = [];

  for (let i = 0; i < arr.length; i += 2) {
    // add 1st element
    let subArray = [arr[i]];
    if (i + 1 < arr.length) {
      // add 2nd element
      subArray.push(arr[i + 1]);
      if (arr[i + 1]?.props?.children == "or" && i + 2 < arr.length) {
        // add 3rd element, if 2nd element was "or"
        subArray.push(arr[i + 2]);
        i += 1;
      }
    } else if (addEmptySpace) {
      subArray.push(<EmptySpace />)
    }
    result.push(subArray);
  }

  return result;
}

export const saveLoanApplicationToRedux = (applicationData) => {
  // Set loan information
  store.dispatch(appActions.selectLocation(applicationData.location));
  store.dispatch(borrowerActions.setLoanInformation({
    location_id: applicationData.location_id ?? "",
    loan_amount: applicationData.loan_amount ?? 0,
    loan_purpose: applicationData.loan_purpose ?? "",
  }));
  // Set personal information
  store.dispatch(borrowerActions.setPersonalInformation({
    first_name: applicationData.first_name ?? "",
    last_name: applicationData.last_name ?? "",
    date_of_birth: convertDateFormatDashToSlash(applicationData.date_of_birth),
    education_level: applicationData.education_level ?? "",
  }));
  // Set main contact
  store.dispatch(borrowerActions.setContactInformation({
    email: applicationData.email ?? "",
    phone_number: applicationData.phone_number ?? "",
  }));
  // Set address information
  store.dispatch(borrowerActions.setAddressInformation({
    address1: applicationData.address1 ?? "",
    address2: applicationData.address2 ?? "",
    city: applicationData.city ?? "",
    state: applicationData.state ?? "",
    postal_code: applicationData.postal_code ?? "",
    property_status: applicationData.property_status ?? "",
  }));
  // Set financial information
  const financialInformation: IDTCFinancialInfo = {
    employment_status: applicationData.employment_status ?? "",
    annual_income: applicationData.annual_income ?? 0,
    pay_frequency: applicationData.pay_frequency ?? "",
    credit_rating: applicationData.credit_rating ?? "",
  };
  if (applicationData.lender_type === "DTC") {
    store.dispatch(borrowerActions.setDTCFinancialInformation(financialInformation));
  } else if (applicationData.lender_type === "DTM") {
    store.dispatch(borrowerActions.setDTMFinancialInformation({
      ...financialInformation,
      company_name: applicationData.company_name || "",
      supervisor_full_name: applicationData.supervisor_full_name || "",
      employment_start_date: convertDateFormatDashToSlash(applicationData.employment_start_date || ""),
      employment_end_date: convertDateFormatDashToSlash(applicationData.employment_end_date || ""),
      first_payment_date: convertDateFormatDashToSlash(applicationData.first_payment_date || ""),
    } as IDTMFinancialInfo));
  }
  // Set down payment information
  store.dispatch(borrowerActions.setDownPaymentInformation({
    down_payment_amount: applicationData.down_payment_amount,
    first_payment_date: convertDateFormatDashToSlash(applicationData.first_payment_date),
  }));
  // Set agreement check
  store.dispatch(borrowerActions.setAgreement(applicationData.is_agreed));
  // Set lender type
  store.dispatch(borrowerActions.setLenderType(applicationData.lender_type));
  // Set application source
  store.dispatch(borrowerActions.setApplicationSource(applicationData.source));
}